export const ADS_API_URL = "https://api.scrapy.software/"
// export const ADS_API_URL = "https://facebookapi.sddoc.in/"


export const API_URL = '/api';
// export const API_URL = 'http://localhost:8000/api';




export const PRICE  = 2
export const RATING  =12
export const TOTAL_REVIEW  = 15