export const AdStatus = [
    {
        id: "1",
        name:"Active",
        value:"1"
    },
    {
        id: "2",
        name:"Inactive",
        value:"2"
    },
    // {
    //     id: "3",
    //     name:"Pending",
    //     value:"pending"
    // },
    // {
    //     id: "4",
    //     name:"Rejected",
    //     value:"rejected"
    // },
    // {
    //     id: "5",
    //     name:"Expired",
    //     value:"expired"
    // },
    // {
    //     id: "6",
    //     name:"Deleted",
    //     value:"deleted"
    // },
    // {
    //     id: "7",
    //     name:"NA",
    //     value:"NA"
    // },
    {
        id: "8",
        name:"All",
        value:"0"
    }
]