

/**
 * Enum for search status
 */
enum SearchStatus {
    Created = 0,
    InProgress = 1,
    Stopped = 2,
    Completed = 3,
    recurrence = 4,
}


export default SearchStatus;