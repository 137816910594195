

const APIParams = {
    name: "",
    country: "",
    content_languages: "",
    filtterStart_date: new Date("01/02/2024"),
    filtterEnd_date: new Date(),
    querry: "",
    ad_status_type: "1",
    reach: 0,
    ad_type: "all",
    media_type: "all",
    publisher_platforms: "all",
    Nextforward_cursor: "",
    Nextbackward_cursor: "",
    Nextcollation_token: "",
    page: "1",
}


export default APIParams