export const MediaType = [
  {
    id: "1",
    name: "Image",
    value: "image",
  },
  {
    id: "2",
    name: "Meme",
    value: "meme",
  },
  {
    id: "5",
    name: "Image and Meme",
    value: "image_and_meme",
  },
  {
    id: "3",
    name: "Video",
    value: "video",
  },
  {
    id: "4",
    name: "None",
    value: "none",
  },
  {
    id: "5",
    name: "All",
    value: "all",
  },
];
